<!--  -->
<template>
  <div class="search">
    <van-nav-bar
        title="搜索"
        left-text="返回"
        left-arrow
        fixed
        @click-left="onClickLeft"
    />
    <van-search
        v-model="name"
        background="#f5f7f9"
        @search="onSearch"
        placeholder="请输入工单编号查询"
    />
    <scroll class="search_scroll" :onLoadMore="onLoadMore">
      <div class="work_content_all">
        <!-- 点击进入工单详情 -->
        <div
            class="work_content_item"
            @click="toWorkDetails(item.id)"
            v-for="(item, index) in workList"
            :key="index"
        >
          <div class="work_number">
            <div class="number">
              <div>工单编号</div>
              <div>{{ item.woCode }}</div>
            </div>
            <div class="work_state">
              <span class="work_state_did" v-if="item.woState == 0"
              >未开始</span
              >
              <span class="work_state_in" v-if="item.woState == 1">执行中</span>
              <span class="work_state_end" v-if="item.woState == 2"
              >已结束</span
              >
              <span class="work_state_cancel" v-if="item.woState == 3"
              >已取消</span
              >
            </div>
          </div>
          <div class="work_name">
            <div class="name">产品名称:</div>
            <div>{{ item.productName }}</div>
          </div>
          <div class="work_progress">
            <div class="work_progress_name">工单进度:</div>
            <div class="work_progress_mun">
              {{
                item.goodProductCounts == null ? "0" : item.goodProductCounts
              }}/{{ item.planCounts }} {{ item.productUnit }}
            </div>
          </div>
          <div class="work_time">
            <div class="time_name">计划时间:</div>
            <div class="time">
              {{ item.planStartTime | dateFormat }}~{{
                item.planEndTime | dateFormat
              }}
            </div>
          </div>
        </div>
      </div>
    </scroll>
  </div>
</template>

<script>
import scroll from "@/components/scroll/index";
import {getWorkList} from "@/api/work.js";
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    scroll,
  },
  data() {
    //这里存放数据
    return {
      name: "",
      total: 0,
      workList: [],
      pageIndex: 1,
      pageSize: 10,
    };
  },
  //监听属性 类似于data概念
  computed: {
    ...mapGetters([
      'departmentCode'
    ]),
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    onLoadMore(done) {
      setTimeout(() => {
        if (!this.enableLoadMore) {
          return;
        }
        this.page = this.page + 1;
        this.getListData();
        done();
      }, 200);
    },
    onClickLeft() {
      this.$router.replace({path: "/index"});
    },
    ...mapActions(["work/getShareWorkId"]),
    async onSearch() {
      try {
        const res = await getWorkList({
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
          woStateList: [],
          keyword: null,
          departmentCode: this.departmentCode,
          woCode: this.name
        });
        if (res.records.length == 0) {
          this.$toast("工单不存在，请重新搜索");
        } else {
          this.workList = res.records;
          this.$toast.success("搜索成功");
        }
      } catch (err) {
        console.log(err);
      }
    },
    toWorkDetails(id) {
      this.$router.push("/workDetails");
      this["work/getShareWorkId"](id);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
  },
};
</script>
<style lang='less' scoped>
.search {
  width: 100vw;
  height: 100vh;

  /deep/ .van-search {
    width: 100vw;
    position: fixed;
    top: 79px;
    left: 0;
    z-index: 100;

    .van-search__content {
      background-color: #fff;
      border-radius: 5px;
    }
  }

  .search_scroll {
    height: calc(100vh - 100px);
    padding-top: 160px;
  }

  .work_content_all {
    color: #999;

    .work_content_item {
      width: 95%;
      margin: auto;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 10px;
      margin-top: 30px;
      padding: 30px 16px;

      .work_number {
        display: flex;
        justify-content: space-between;

        .number {
          display: flex;
          justify-content: start;
          align-items: center;

          div:nth-child(1) {
            text-align: center;
            line-height: 1.2;
            padding: 2px;
            font-size: 24px;
            color: #1989fa;
            border: 1px solid #1989fa;
          }

          div:nth-child(2) {
            margin-left: 20px;
            color: black;
            font-size: 36px;
            font-weight: 700;
          }
        }

        .work_state {
          font-size: 22px;

          .work_state_in {
            color: blue;
          }

          .work_state_end {
            color: black;
          }

          .work_state_cancel {
            color: red;
          }
        }
      }

      .work_name {
        margin-top: 16px;
        display: flex;
        justify-content: start;
        font-size: 28px;

        div:nth-child(2) {
          margin-left: 10px;
        }
      }

      .work_progress {
        margin-top: 16px;
        font-size: 28px;
        display: flex;
        justify-content: start;

        div:nth-child(2) {
          margin-left: 10px;
        }
      }

      .work_time {
        margin-top: 16px;
        font-size: 28px;
        display: flex;
        align-items: center;
        justify-content: start;

        div:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
